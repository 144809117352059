import React, { useState, useEffect } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SEOAdditional from "../components/seo-additional";
import Cta from "../components/cta";
import Hero from "../components/hero";
import Feature from "../components/feature";
import FeatureList from "../components/feature-list";
import Quote from "../components/quote";
// import Bigtitle from "../components/bigtitle";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

// import { ReactComponent as Sf1 } from "../images/sf-1.svg";
import { ReactComponent as Sf2 } from "../images/sf-2.svg";

import Image from "../components/image";

import { UilAngleDown } from "@iconscout/react-unicons";

function Icon({ icon }) {
    let uil = {
        chevronDown: UilAngleDown,
    };

    const MyIcon = uil[icon];
    return <MyIcon size="24" color="#718096" />;
}

function GetImage({ data }) {
    if (data == "sf-1") {
        return <Image filename="sf-1.png" />;
    }
    if (data == `sf-2`) {
        return <Sf2 />;
    }
    if (data == "sf-3") {
        return <Image filename="sf-3.png" />;
    }
    return null;
}

function Storefront({ data }) {
    let d = data.contentfulPageNgorderStorefront;
    let features = d.features;
    let bigTitle = d.bigTitle;

    // Fetch latest news data
    const [featuredShops, setFeaturedShops] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(1);

    useEffect(() => {
        fetch(
            `${process.env.GATSBY_NGORDER_APP_URL}/API/landing_storefront_logo`
        )
            .then((response) => response.json())
            .then((resultData) => {
                setFeaturedShops(resultData);
            });
    }, []);

    return (
        <Layout>
            <SEO
                keywords={d.seo.keywords}
                title={d.seo.title}
                description={d.seo.description}
            />

            <Hero
                props={{
                    label: d.label,
                    title: d.title,
                    desc: renderRichText(d.subtitle),
                    style: {
                        // color: `#665264`,
                        // backgroundColor: `#F6C7F2`,
                        // backgroundImage: `linear-gradient(0, rgba(0,0,0, .2) 0%, rgba(0,0,0, .2) 100%), url(${HeroImg})`,
                        backgroundRepeat: `no-repeat`,
                        backgroundPosition: `20% 100%`,
                        // backgroundSize: `cover`,
                    },
                    width: `w-full md:w-1/2`,
                    align: `text-left`,
                    contentImg: <img src="../images/sf-hero.png" />,
                }}
            />

            {bigTitle.map((item) => (
                <section
                    key={item.label}
                    className="bg-white dark:bg-gray-800 py-4"
                >
                    <div
                        className={`flex flex-col ${
                            item.imagePosition == `left`
                                ? `md:flex-row`
                                : `md:flex-row-reverse`
                        } w-full max-w-screen-xl mx-auto space-x-2 items-center mb-4`}
                    >
                        <div className="w-full md:w-1/2 px-4 md:px-0">
                            <GetImage data={item.image} />
                        </div>

                        <div className="w-full md:w-1/2">
                            <Feature
                                key={item.title}
                                title={item.title}
                                description={item.subtitle}
                                hasImg="false"
                                padding="py-8 px-4 md:px-8 md:py-10"
                            />

                            <div className="flex flex-wrap w-full">
                                {features
                                    .filter((i) => i.label == item.label)
                                    .map((item) => (
                                        <FeatureList
                                            key={item.title}
                                            props={item}
                                            width="w-1/2"
                                            titleSize="text-xl"
                                            titleMarginBottom="mb-4"
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                </section>
            ))}

            <Quote
                props={{
                    image: d.quote.image,
                    imageWidth: d.quote.imageWidth,
                    content: d.quote.content,
                    name: d.quote.name,
                    company: d.quote.company,
                    padding: `py-20`,
                }}
            />

            <section className="max-w-6xl mx-auto py-10 hidden">
                <div className="flex flex-col w-full text-center">
                    <div className="max-w-3xl mx-auto">
                        <img src={d.bigImage} alt="" />
                    </div>
                </div>
            </section>

            <section className="bg-white dark:bg-gray-800">
                <div className="w-full max-w-6xl mx-auto px-4 py-8 md:px-8 md:py-16">
                    <h2 className="text-xl mb-6 md:mb-10 text-center leading-tight font-display max-w-xl mx-auto">
                        {d.brands.title}
                    </h2>

                    <div className="flex flex-wrap justify-around items-center max-w-3xl mx-auto">
                        {featuredShops.map((item) => (
                            <img
                                key={item}
                                src={item.logo}
                                alt=""
                                style={{
                                    width:
                                        item.width !== null
                                            ? parseInt(item.width)
                                            : `auto`,
                                    height:
                                        item.height !== null
                                            ? parseInt(item.height)
                                            : `auto`,
                                }}
                                className="content-center w-auto flex mx-4 mb-6"
                            />
                        ))}
                    </div>
                </div>
            </section>

            <Cta />

            {d.seoAdditional.length ? (
                <section className="px-4 bg-gray-50 dark:bg-gray-800 space-y-5">
                    {d.seoAdditional.map((seoAdd, key) => (
                        <SEOAdditional
                            key={`seo-additional-${key}`}
                            titleTag={seoAdd.titleTag}
                            title={seoAdd.title}
                            content={seoAdd.content}
                            className={key >= itemsToShow ? "hidden" : ""}
                        />
                    ))}

                    {itemsToShow == 1 ? (
                        <div className="w-full max-w-6xl mx-auto">
                            <button
                                type="button"
                                className="flex flex-row text-gray-500 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-300 hover:underline border-b border-dashed"
                                onClick={() =>
                                    setItemsToShow(d.seoAdditional.length)
                                }
                            >
                                <span>Selengkapnya</span>
                                <Icon icon={"chevronDown"} />
                            </button>
                        </div>
                    ) : (
                        ""
                    )}
                </section>
            ) : (
                ""
            )}
        </Layout>
    );
}

Icon.propTypes = {
    icon: PropTypes.string,
};
GetImage.propTypes = {
    data: PropTypes.string,
};

Storefront.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        contentfulPageNgorderStorefront {
            seo {
                title
                description
                keywords
            }
            seoAdditional {
                titleTag
                title
                content
            }
            label
            title
            subtitle {
                raw
            }
            features {
                label
                title
                icon
                description
            }
            quote {
                name
                company
                content
                image
                imageWidth
            }
            bigTitle {
                image
                imagePosition
                label
                title
                subtitle
            }
            brands {
                title
                items {
                    logo
                    url
                }
            }
        }
    }
`;

export default Storefront;
