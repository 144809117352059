import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";

function Quote({ props }) {
    return (
        <section
            className={`${props.padding} max-w-6xl mx-auto flex flex-col-reverse md:flex-row items-center space-x-8`}
        >
            <div className="md:w-2/3 pr-4">
                <blockquote className="text-lg md:text-xl mb-6 font-normal text-gray-600 dark:text-gray-200 relative px-8 leading-relaxed">
                    <div className="absolute -top-4 -left-2 fill-current -z-1">
                        <img
                            src="../icons/myicons/iconmonstr-quote-5.svg"
                            alt="quote"
                            className="mb-3 w-5 h-5 opacity-80"
                        />
                    </div>
                    {parse(props.content)}
                    <div className="absolute -bottom-4 -right-2 fill-current -z-1">
                        <img
                            src="../icons/myicons/iconmonstr-quote-7.svg"
                            alt="quote"
                            className="mb-3 w-5 h-5 opacity-80"
                        />
                    </div>
                </blockquote>
                <p className="px-8 leading-relaxed">
                    <span className="font-bold text-lg">{props.name}</span>
                    <br />
                    <span>&mdash;{props.company}</span>
                </p>
            </div>

            <div className={`md:w-${props.imageWidth} mb-6`}>
                <img
                    src={props.image}
                    alt=""
                    className="w-full h-auto rounded-xl"
                />
            </div>
        </section>
    );
}
Quote.defaultProps = {
    padding: `py-10`,
};

Quote.propTypes = {
    props: PropTypes.array,
    content: PropTypes.text,
    name: PropTypes.string,
    company: PropTypes.string,
    image: PropTypes.string,
    imageWidth: PropTypes.string,
    padding: PropTypes.string,
};

export default Quote;
